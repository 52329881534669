// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBoltLightning   as falFaBoltLightning   } from "@fortawesome/pro-light-svg-icons/faBoltLightning";
import { faCalendarDays    as falFaCalendarDays    } from "@fortawesome/pro-light-svg-icons/faCalendarDays";
import { faDisplay         as falFaDisplay         } from "@fortawesome/pro-light-svg-icons/faDisplay";
import { faFilm            as falFaFilm            } from "@fortawesome/pro-light-svg-icons/faFilm";
import { faHeadphones      as falFaHeadphones      } from "@fortawesome/pro-light-svg-icons/faHeadphones";
import { faIdCard          as falFaIdCard          } from "@fortawesome/pro-light-svg-icons/faIdCard";
import { faLanguage        as falFaLanguage        } from "@fortawesome/pro-light-svg-icons/faLanguage";
import { faIcons           as falFaIcons           } from "@fortawesome/pro-light-svg-icons/faIcons";
import { faMusic           as falFaMusic           } from "@fortawesome/pro-light-svg-icons/faMusic";
import { faNewspaper       as falFaNewspaper       } from "@fortawesome/pro-light-svg-icons/faNewspaper";
import { faPrint           as falFaPrint           } from "@fortawesome/pro-light-svg-icons/faPrint";
import { faSunglasses      as falFaSunglasses      } from "@fortawesome/pro-light-svg-icons/faSunglasses";
import { faTablet          as falFaTablet          } from "@fortawesome/pro-light-svg-icons/faTablet";
import { faTruck           as falFaTruck           } from "@fortawesome/pro-light-svg-icons/faTruck";

import { faArrowDown       as farFaArrowDown       } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { faArrowLeft       as farFaArrowLeft       } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { faArrowRight      as farFaArrowRight      } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faArrowUp         as farFaArrowUp         } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { faAngleDown       as farFaAngleDown       } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleLeft       as farFaAngleLeft       } from "@fortawesome/pro-regular-svg-icons/faAngleLeft";
import { faAngleRight      as farFaAngleRight      } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faAngleUp         as farFaAngleUp         } from "@fortawesome/pro-regular-svg-icons/faAngleUp";
import { faBars            as farFaBars            } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faCircleInfo      as farFaCircleInfo      } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faCircleQuestion  as farFaCircleQuestion  } from "@fortawesome/pro-regular-svg-icons/faCircleQuestion";
import { faCircleUser      as farFaCircleUser      } from "@fortawesome/pro-regular-svg-icons/faCircleUser";
import { faClock           as farFaClock           } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faComment         as farFaComment         } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faFolder          as farFaFolder          } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faLink            as farFaLink            } from "@fortawesome/pro-regular-svg-icons/faLink";
import { faMagnifyingGlass as farFaMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlass";
import { faPodcast         as farFaPodcast         } from "@fortawesome/pro-regular-svg-icons/faPodcast";
import { faTag             as farFaTag             } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faXmark           as farFaXmark           } from "@fortawesome/pro-regular-svg-icons/faXmark";

import { faSunglasses      as fasFaSunglasses      } from "@fortawesome/pro-solid-svg-icons/faSunglasses";

import { faFacebookF       as fabFaFacebookF       } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faGoodreadsG      as fabFaGoodreadsG      } from "@fortawesome/free-brands-svg-icons/faGoodreadsG";
import { faInstagram       as fabFaInstagram       } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter         as fabFaTwitter         } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faXTwitter        as fabFaXTwitter        } from "@fortawesome/free-brands-svg-icons/faXTwitter";
import { faYoutube         as fabFaYoutube         } from "@fortawesome/free-brands-svg-icons/faYoutube";

/**
 * Add light icons
 */
library.add(falFaBoltLightning, falFaCalendarDays, falFaDisplay, falFaFilm, falFaHeadphones, falFaIcons, falFaIdCard, falFaLanguage, falFaMusic, falFaNewspaper, falFaPrint, falFaSunglasses, falFaTablet, falFaTruck);

/**
 * Add regular icons
 */
library.add(farFaArrowDown, farFaArrowLeft, farFaArrowRight, farFaArrowUp, farFaAngleDown, farFaAngleLeft, farFaAngleRight, farFaAngleUp, farFaBars, farFaCircleInfo, farFaCircleQuestion, farFaCircleUser, farFaClock, farFaComment, farFaFolder, farFaLink, farFaMagnifyingGlass, farFaPodcast, farFaTag, farFaXmark);

/**
 * Add solid icons
 */
library.add(fasFaSunglasses);

/**
 * Add brand icons
 */
library.add(fabFaFacebookF, fabFaGoodreadsG, fabFaInstagram, fabFaTwitter, fabFaXTwitter, fabFaYoutube);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
